import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { getStringStyleParamsValue } from './stylesParams';
import { AlignmentOptions, ImageSizeOptions } from '../../types/types';

export const cssVarsGenerator = ({
  styleParams,
  isMobile,
  isRTL,
}: Parameters<CustomCssVarsFn>[0]) => {
  const isServiceImageVisible = styleParams.booleans.isServiceImageVisible;
  const isServiceImageOnRight = styleParams.booleans.isServiceImageOnRight;
  const alternateImageInfoSides = styleParams.booleans.alternateImageInfoSides;
  const serviceTextAlignment = getStringStyleParamsValue(
    styleParams.strings.serviceTextAlignment,
  ) as AlignmentOptions;
  const serviceTitleAlignemnt = getStringStyleParamsValue(
    styleParams.strings.titleAlignment,
  ) as AlignmentOptions;
  const serviceImageSize = getStringStyleParamsValue(
    styleParams.strings.serviceImageSize,
  ) as ImageSizeOptions;

  return {
    classicImagePosition: () => {
      if (alternateImageInfoSides) {
        return isServiceImageOnRight
          ? { evenImageDirection: 'rtl', oddImageDirection: 'ltr' }
          : { evenImageDirection: 'ltr', oddImageDirection: 'rtl' };
      } else {
        return isServiceImageOnRight
          ? { evenImageDirection: 'rtl', oddImageDirection: 'rtl' }
          : { evenImageDirection: 'ltr', oddImageDirection: 'ltr' };
      }
    },
    overlappingImagePosition: () => {
      const overlappingGridAreaImageInRight = `". media"
        "info media"
        ". media"`;

      const overlappingGridAreaImageInLeft = `"media margin-top"
        "media info"
        "media margin-bottom"`;

      if (alternateImageInfoSides) {
        return isServiceImageOnRight
          ? {
              evenOverlappingGridArea: overlappingGridAreaImageInRight,
              oddOverlappingGridArea: overlappingGridAreaImageInLeft,
              evenOverlappingImageMargin: '0 0 0 -60px',
              oddOverlappingImageMargin: '0 -60px 0 0',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
            }
          : {
              evenOverlappingGridArea: overlappingGridAreaImageInLeft,
              oddOverlappingGridArea: overlappingGridAreaImageInRight,
              evenOverlappingImageMargin: '0 -60px 0 0',
              oddOverlappingImageMargin: '0 0 0 -60px',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
            };
      } else {
        return isServiceImageOnRight
          ? {
              evenOverlappingGridArea: overlappingGridAreaImageInRight,
              oddOverlappingGridArea: overlappingGridAreaImageInRight,
              evenOverlappingImageMargin: '0 0 0 -60px',
              oddOverlappingImageMargin: '0 0 0 -60px',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
            }
          : {
              evenOverlappingGridArea: overlappingGridAreaImageInLeft,
              oddOverlappingGridArea: overlappingGridAreaImageInLeft,
              evenOverlappingImageMargin: '0 -60px 0 0',
              oddOverlappingImageMargin: '0 -60px 0 0',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
            };
      }
    },
    serviceDetailsTextAlignment: () => {
      switch (serviceTextAlignment) {
        case AlignmentOptions.CENTER:
          return {
            serviceDetailsTextAlign: 'center',
            serviceDetailsMarginLeft: 'auto',
            serviceDetailsMarginRight: 'auto',
          };
        case AlignmentOptions.LEFT:
          return {
            serviceDetailsTextAlign: 'left',
            serviceDetailsMarginLeft: 0,
            serviceDetailsMarginRight: 'auto',
          };
        case AlignmentOptions.RIGHT:
          return {
            serviceDetailsTextAlign: 'right',
            serviceDetailsMarginLeft: 'auto',
            serviceDetailsMarginRight: 0,
          };
      }
    },
    serviceTitleAlignment: () => {
      switch (serviceTitleAlignemnt) {
        case AlignmentOptions.CENTER:
          return {
            titlePaddingLeft: '10%',
            titlePaddingRight: '10%',
            titleTextAlign: 'center',
          };
        case AlignmentOptions.LEFT:
          return {
            titlePaddingLeft: 'initial',
            titlePaddingRight: '20%',
            titleTextAlign: 'left',
          };
        case AlignmentOptions.RIGHT:
          return {
            titlePaddingLeft: '20%',
            titlePaddingRight: 'initial',
            titleTextAlign: 'right',
          };
      }
    },
    serviceImageSize: () => {
      switch (serviceImageSize) {
        case ImageSizeOptions.SMALL:
          return { serviceImageSize: isMobile ? '40px' : '84px' };
        case ImageSizeOptions.MEDIUM:
          return { serviceImageSize: isMobile ? '56px' : '110px' };
        case ImageSizeOptions.LARGE:
          return { serviceImageSize: isMobile ? '84px' : '140px' };
      }
    },
  } satisfies Record<string, () => ReturnType<CustomCssVarsFn>>;
};

export const generateAllCssVars: CustomCssVarsFn = (args) => {
  const cssVarsGenerators = cssVarsGenerator(args);
  type keysType = keyof typeof cssVarsGenerators;
  return (Object.keys(cssVarsGenerators) as keysType[]).reduce((css, key) => {
    const cssGeneratorFn = cssVarsGenerators[key];
    const generatedCss = cssGeneratorFn();
    return { ...css, ...generatedCss };
  }, {});
};
